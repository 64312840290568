/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.dark-layout {
	.swiper-container {
		&:not(.swiper-parallax) {
			.swiper-slide {
				background-color: transparent !important;
			}
		}
	}
}
.modal-slide-in {
	.modal-dialog.sidebar-lg.modal-answer {
		width: 35rem;
	}
}
.single-body-part {
	.body-image {
		height: 170px;
		width: 100%;
	}
}
.spinner-border-lg.spinner-border {
	width: 3.5rem;
	height: 3.5rem;
}
.toggle-view-iocns {
	position: absolute;
	right: 25px;
	margin-top: -50px;
}

.program-container{
	background-color: #151518;
	min-height: 100vh;
}
.program-card.card, .program-container .card{
	background-color: transparent;
    background-image: radial-gradient(at top left, #2A2657 0%, #1C1B1B 70%);
	border-radius: 25px 25px 25px 25px;
}
.program-card::-webkit-scrollbar{width:10px; border-top-left-radius: 30px;}
.program-card::-webkit-scrollbar-thumb{background:#8176FF;}

.program-container .btn{
	background-color: transparent ;
    background-image: linear-gradient(
60deg, #6B5EFF 0%, #8176FF 82%);
border: none;
}
.program-container .btn:hover {
    color: #ffffff;
    background-color: transparent;
    background-image: linear-gradient(
180deg, #8176FF 0%, #6B5EFF 100%);
}
.program-container .form-control{
border-style: solid;
    border-width: 0px 0px 2px 0px;
    border-color: #534d9f !important;
	border-radius: 0px !important;
}

// .form-control:not(.is-valid):not(.is-invalid):not(:focus){
// 	border-color: #ea5455 !important;
// }
.program-container .form-control.is-invalid {
    border-color: #ea5455 !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ea5455' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ea5455' stroke='none'/%3e%3c/svg%3e") !important;
    background-repeat: no-repeat !important;
	
    background-size: calc(0.725em + 0.438rem) calc(0.725em + 0.438rem) !important;
}
.single-body-part .body-parent .body-image{
transition: all .2s ease-in-out;
}
.single-body-part .body-parent:hover .body-image{
	border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #8176FF;
	transform: scale(1.05);
}
.single-body-part .body-parent:hover h5{
	color: #8176FF;
}

.program-container .select__control {
    border-radius: 30px;
}

.human-svg{
	// height: 85vh;
	width: 55%;
}
.human-svg path{fill:#E8BEAC}
.human-svg .selected:hover{
	fill: #ffffff;
	cursor: pointer;
}


[dir] .dark-layout .rdw-editor-wrapper .rdw-editor-toolbar {
    background: #161d31;
    border-color: #3b4253;
}
[dir] .dark-layout .rdw-editor-wrapper .rdw-editor-toolbar .rdw-option-wrapper {
    background: #161d31;
}
.dark-layout .rdw-editor-wrapper .rdw-editor-toolbar .rdw-option-wrapper img {
    filter: invert(100%);
}
[dir] .dark-layout .rdw-editor-wrapper .rdw-editor-toolbar .rdw-dropdown-wrapper {
    background: #161d31;
    border-color: #3b4253;
}
[dir] .rdw-editor-wrapper .rdw-editor-toolbar .rdw-dropdown-wrapper {
    border: 1px solid #ebe9f1;
}
[dir] .dark-layout .rdw-editor-wrapper .rdw-editor-toolbar .rdw-dropdown-wrapper .rdw-dropdown-carettoopen {
    border-top-color: #b4b7bd;
}
[dir] .dark-layout .rdw-editor-wrapper .rdw-editor-main {
    border-color: #3b4253;
}